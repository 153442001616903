@use '../../../node_modules/@byebyeq/bbq-engine-components' as bbq;

// Plus imports for other components in your app.
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
	margin-top: env(safe-area-inset-top);
}

html,
body {
	height: 100%;

	* {
		box-sizing: border-box;
	}
}

body {
	margin: 0;
	font-family: Montserrat, Helvetica, sans-serif;
	background-color: #fafafa;
	padding-top: 64px;

	@media (max-width: 599px) {
		padding-top: 56px;
	}
}

// NOTE LAYOUT HELPERS

.card {
	margin: 20px auto;
	min-width: 120px;
}

.flex-container {
	.mat-dialog-container {
		display: flex;
		flex-direction: column;
	}
}

// https://material.io/design/layout/responsive-layout-grid.html#breakpoints
.flexible-card {
	max-width: 50%;

	@media (max-width: 1439px) {
		max-width: 66%;
	}

	@media (max-width: 719px) {
		max-width: 100%;
	}
}

.full-width {
	width: 100%;
}

.half-width {
	width: 50%;
}

.has-mat-hint {
	margin-bottom: 2em;
}

.mat-toolbar h2 {
	font-size: 30px;
	margin-top: 4px;
}

.toolbar-spacer {
	flex: 1 1 auto;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
	line-height: 36px;
}

.form-width {
	@include bbq.form-control-width;
}

.panel-error {
	@apply bg-red-500 text-white text-center;
}

.panel-success {
	@apply bg-green-500 text-white text-center;
}
