@use "sass:map";
@import "src/app/+coach/coach-add-dialog/coach-add-dialog.component.theme";
@import "src/app/+coach/coach-update-dialog/coach-update-dialog.component.theme";
@import "src/app/+course/course-single/course-single-customer/course-single-consumer.component.theme";
@import "src/app/+course/course-verify-buyer-dialog/course-verify-buyer-dialog.component.theme";
@import "src/app/+course-module/course-module-list/course-module-list-customer/course-module-list-consumer.component.theme";
@import "src/app/+course-module/course-module-single/course-module-single-customer/course-module-single-consumer.component.theme";
@import "src/app/+lesson/lesson-list/lesson-list-customer/lesson-list-consumer.component.theme";
@import "src/app/+lesson/lesson-single/lesson-single-customer/lesson-single-consumer.component.theme";
@import "src/app/+main/menu/menu.component.theme";
@import "src/app/+main/app-footer/app-footer.component.theme";
@include app-menu($ott-basic-theme);
@include app-app-footer($ott-basic-theme);
@include app-coach-add-dialog($ott-basic-theme);
@include app-coach-update-dialog($ott-basic-theme);
@include app-course-single-consumer($ott-basic-theme);
@include app-course-verify-buyer-dialog($ott-basic-theme);
@include app-course-module-list-consumer($ott-basic-theme);
@include app-course-module-single-consumer($ott-basic-theme);
@include app-lesson-list-consumer($ott-basic-theme);
@include app-lesson-single-consumer($ott-basic-theme);

// @import "@angular/material/theming";

.active {
	background-color: mat-color(map.get($ott-basic-theme, background), hover);
}

.mat-form-field-can-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-element {
	line-height: 1.4;
}
