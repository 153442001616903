@use "sass:map";
@import "@angular/material/theming";

@mixin app-lesson-single-consumer($theme) {
	app-lesson-single-consumer {
		h4 {
			color: mat-color(map.get($theme, primary));
		}

		caption,
		a.file-url:active {
			color: mat-color(map.get($theme, accent));
		}
	}
}
