@use 'sass:map';
@import '@angular/material/theming';

@mixin app-menu($theme) {
	app-menu {
		.app-version {
			color: white;
		}

		#safe-area-top-filler {
			background-color: mat-color(map.get($theme, primary), 600);
		}

		nav {
			a {
				color: white;
			}

			&:hover {
				a:hover {
					color: mat-color(map.get($theme, accent));
				}
			}

			li {
				.sub-menu {
					background-color: #292a2d;

					li {
						a {
							color: white;

							&:hover {
								color: mat-color(map.get($theme, accent));
							}
						}
					}
				}

				&:hover {
					.sub-menu {
						&::after {
							background-color: #292a2d;
						}

						&:hover {
							a:hover {
								color: mat-color(map.get($theme, accent));
							}
						}
					}
				}
			}
		}
	}

	.mat-nav-list .mat-list-item:hover,
	.mat-menu-content .mat-menu-item:hover {
		color: mat-color(map.get($theme, accent));

		mat-icon {
			color: mat-color(map.get($theme, accent));
		}
	}
}
