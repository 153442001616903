@use "sass:map";
@import "@angular/material/theming";

@mixin app-lesson-list-consumer($theme) {
	.lesson-list-container {
		app-lesson-list-item-consumer {
			&:hover,
			&.active {
				caption {
					color: mat-color(map.get($theme, accent));
				}

				h3 {
					color: mat-color(map.get($theme, primary));
				}
			}
		}
	}
}
