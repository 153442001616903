@use "sass:map";
@import "@angular/material/theming";

@mixin app-coach-update-dialog($theme) {
	app-coach-update-dialog {
		.verified {
			background-color: mat-color(map.get($theme, accent));
			color: mat-color(map.get($theme, accent), "500-contrast");
		}

		.not-verified {
			background-color: mat-color(map.get($theme, warn));
			color: mat-color(map.get($theme, warn), "500-contrast");
		}
	}
}
