@import "@angular/material/theming";

@mixin app-app-footer($theme) {
	app-app-footer {
		#footer__primary-toolbar {
			background-color: #000;

			// background-color: #292a2d;
		}

		#footer__secondary-toolbar {
			background-color: #080808;

			// background-color: #323639;
			color: #ccc;
		}

		a,
		.social-link {
			color: white;

			&:hover {
				color: #777779;
			}
		}
	}
}
