@use "sass:map";
@import "@angular/material/theming";

@mixin app-course-module-single-consumer($theme) {
	app-course-module-single-consumer {
		.support-btn:hover {
			background-color: mat-color(map.get($theme, primary)) !important;
		}
	}
}
